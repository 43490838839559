<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="dashboardLogo"
                alt="login"
                class="mx-auto"
                style="width: 80%; height: 215px"
                v-if="showLogo"
              />
              <img
                src="@/assets/images/pages/login.png"
                alt="login"
                class="mx-auto w-100"
                v-else
              />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 my-10">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>
                <!-- <form> -->
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full no-icon-border"
                  v-on:keyup.enter="login"
                />
                <span class="text-danger text-sm">{{
                  errors.first("email")
                }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:6'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  label-placeholder="Password"
                  v-model="password"
                  class="w-full mt-6 no-icon-border"
                  v-on:keyup.enter="login"
                />
                <span v-if="!password.length > 0" class="text-danger text-sm">{{
                  errors.first("password")
                }}</span>

                <div class="flex flex-wrap justify-between my-5">
                  <router-link to="/pages/forgot-password"
                    >Forgot Password?</router-link
                  >
                </div>
                <vs-button class="float-right" @click="login">Login</vs-button>
                <!-- </form> -->
                <!-- <vs-divider>OR</vs-divider> -->

                <!-- <div class="social-login flex flex-wrap justify-between">
                  <div class="social-login-buttons flex flex-wrap items-center mt-4">
                    <div
                      class="bg-google pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4"
                      @click="loginWithGoogle"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="google"
                        class="text-white h-4 w-4 svg-inline--fa fa-google fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 488 512"
                      >
                        <path
                          fill="currentColor"
                          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <vs-button class="mt-4" @click="loginAuth0" color="#eb5424">Auth0</vs-button>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import { TokenService, ProfileService } from "@/services/storage.service.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      currentUser: "",
      checkbox_remember_me: false,
      organization: [],
      userDetails: {},
      dashboardLogo: "",
      showLogo: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  beforeMount() {
    localStorage.clear();
  },
  mounted() {
    console.log(window);
    // https://i.ibb.co/9nS2pDF/Whats-App-Image-2020-10-07-at-11-14-2.png
    if (window.dashboardLogo) {
      this.dashboardLogo = window.dashboardLogo;
      this.showLogo = true;
    }
  },
  methods: {
    getUserProfileInfo: function () {
      UserService.getProfileInfo()
        .then((response) => {
          const { data } = response;
          if (data && data.Status) {
            this.userDetails = data.data;
            console.log(this.userDetails, "yyyy");
            this.organization = this.userDetails.organization_data;
            localStorage.setItem("user_id", data.data.user_id);
            if (
              this.userDetails.user_role &&
              this.userDetails.user_role != "Super Admin" &&
              this.userDetails.user_role != "Admin"
            ) {
              if (this.userDetails.modulePermissions.length > 0) {
                localStorage.setItem(
                  "moduleAccess",
                  this.userDetails.modulePermissions[0].accessModuleId
                );
                localStorage.setItem(
                  "permissionPageAccess",
                  this.userDetails.modulePermissions[0].pgAccessId
                );
                localStorage.setItem(
                  "projectIdsAccess",
                  this.userDetails.modulePermissions[0].project_ids
                );
              } else {
                setTimeout(() => {
                  // this.$router.push({
                  //   path: "/contractView"
                  // });
                  this.$router.push({
                    // path: "/pages/not-authorized",
                    // path: "/dashboard/analytics",
                    path: "/pages/landingPage",
                  });
                }, 500);
              }
            }

            if (this.organization.length == 1) {
              if (this.userDetails.dashboard_user.length > 0) {
                let levelProject = [];
                let userlevelProject = [];
                this.userDetails.dashboard_user.map((x) => {
                  levelProject.push(x.project_id);
                  userlevelProject.push(x.user_level);
                });

                localStorage.setItem("levelProject", levelProject.toString());
                localStorage.setItem(
                  "userlevelProject",
                  userlevelProject.toString()
                );
              }
              if (
                this.userDetails.user_role &&
                this.userDetails.user_role != "Super Admin" &&
                this.userDetails.user_role != "Admin"
              ) {
                if (this.userDetails.modulePermissions.length > 0) {
                  localStorage.setItem(
                    "moduleAccess",
                    this.userDetails.modulePermissions[0].accessModuleId
                  );
                  localStorage.setItem(
                    "permissionPageAccess",
                    this.userDetails.modulePermissions[0].pgAccessId
                  );
                  localStorage.setItem(
                    "projectIdsAccess",
                    this.userDetails.modulePermissions[0].project_ids
                  );
                }
              }

              localStorage.setItem(
                "OrganizationID",
                this.organization[0].organization_id
              );

              UserService.getProfileInfoViaOrg(
                this.organization[0].organization_id
              )
                .then((response) => {
                  const { data } = response;
                  if (data.data.user_id) {
                    localStorage.setItem("user_id", data.data.user_id);
                  }
                  if (data && data.Status) {
                    let profile = data.data;
                    let userRole = data.data.user_role;
                    let dashboardUser = data.data.dashboard_user;
                    let contractProjects = data.data.contractProjects;
                    this.$vs.loading.close();
                    localStorage.setItem(
                      "isPurchaseOrder",
                      profile.isPurchaseOrder
                    );
                    let checkUserType = data.data.checkUserType;

                    // let checkUserType = data.data.checkUserType;
                    localStorage.setItem("UserType", checkUserType);
                    localStorage.setItem(
                      "isAdvanced",
                      data.data.advance_isActive
                    );
                    localStorage.setItem(
                      "isCreditNote",
                      data.data.creditNote_isActive
                    );

                    localStorage.setItem(
                      "isShootLocation",
                      data.data.isShootLocation
                    );
                    localStorage.setItem("isTally", data.data.tally_isActive);
                    localStorage.setItem(
                      "digiSign",
                      data.data.digiSign_isActive
                    );
                    if (checkUserType == 1) {
                      if (
                        userRole == "AdminManager" ||
                        userRole == "Moderator"
                      ) {
                        userRole = "User";
                      }
                    }
                    let userName = data.data.user_name;
                    localStorage.setItem("UserRole", userRole);
                    localStorage.setItem("UserName", userName);
                    localStorage.setItem("user_id", data.data.user_id);
                    if (checkUserType == 1) {
                      let userName = data.data.user_name;
                      localStorage.setItem("UserRole", userRole);
                      localStorage.setItem("UserName", userName);

                      setTimeout(() => {
                        // this.$router.push({
                        //   path: "/contractView"
                        // });
                        this.$router.push({
                          path: "/",
                        });
                      }, 500);

                      return false;
                    }

                    if (userRole == "User") {
                      if (
                        dashboardUser.length == 0 &&
                        contractProjects.length == 0
                      ) {
                        setTimeout(() => {
                          // this.$router.push({
                          //   path: "/contractView"
                          // });
                          this.$router.push({
                            path: "/",
                          });
                        }, 500);
                        // this.logout();
                      } else {
                        let userName = data.data.user_name;
                        localStorage.setItem("UserRole", userRole);
                        localStorage.setItem("UserName", userName);
                        setTimeout(() => {
                          this.$nextTick(() => {
                            this.$store.dispatch("setPermissions");
                            this.$forceUpdate();
                          });
                        }, 500);
                        setTimeout(() => {
                          this.$router.push({
                            path: "/",
                          });
                        }, 500);
                      }
                    } else if (
                      userRole == "Super Admin" ||
                      userRole == "Admin" ||
                      userRole == "Admin Manager"
                    ) {
                      let userName = data.data.user_name;
                      localStorage.setItem("UserRole", userRole);
                      localStorage.setItem("UserName", userName);
                      setTimeout(() => {
                        this.$nextTick(() => {
                          this.$store.dispatch("setPermissions");
                          this.$forceUpdate();
                        });
                      }, 500);
                      // ProfileService.saveProfile(profile);
                      setTimeout(() => {
                        this.$router.push({
                          path: "/",
                        });
                      }, 500);
                    } else {
                      let userName = data.data.user_name;
                      localStorage.setItem("UserRole", userRole);
                      localStorage.setItem("UserName", userName);

                      setTimeout(() => {
                        this.$nextTick(() => {
                          this.$store.dispatch("setPermissions");
                          this.$forceUpdate();
                        });
                      }, 500);
                      // ProfileService.saveProfile(profile);
                      setTimeout(() => {
                        this.$router.push({
                          path: "/",
                        });
                      }, 500);
                    }
                  }
                })
                .catch((error) => {
                  this.$vs.notify({
                    title: "error",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "warning",
                  });
                });
            } else {
              this.$router.push({
                path: "/pages/landingPage",
              });
            }
            // } else {
            //   this.$vs.notify({
            //     title: "Access Denied",
            //     iconPack: "feather",
            //     icon: "icon-alert-circle",
            //     color: "warning"
            //   });
            // }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        });
    },
    login() {
      // const payload = {
      //   checkbox_remember_me: this.checkbox_remember_me,
      //   userDetails: {
      //     email: this.email,
      //     password: this.password
      //   },
      //   notify: this.$vs.notify
      // };
      // this.$store.dispatch("auth/loginAttempt", payload);
      if (this.email.length > 0 && this.password.length > 0) {
        const payload = {
          user_name: this.email,
          password: this.password,
        };
        let that = this;
        this.$vs.loading();

        UserService.LoginUser(payload)
          .then((response) => {
            const { data } = response;

            if (data && data.Status == true) {
              let token = data.data.access_token;
              // let user = data.data.user_name;
              // let userRole = data.data.role;
              this.currentUser = data.data.role;
              let tempToken = data.data.temp_token;
              TokenService.saveToken(token);

              // localStorage.setItem("UserName", user);
              // localStorage.setItem("UserRole", userRole);
              localStorage.setItem("TempToken", tempToken);
              this.getUserProfileInfo();
              setTimeout(() => {
                this.$nextTick(() => {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title: "Login Attempt",
                    text: data.Message,
                    iconPack: "feather",
                    color: "success",
                  });

                  // this.$router.push({
                  //   path: "/pages/landingPage"
                  // });
                });
              }, 1000);
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Login Attempt",
                text: data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
          });
      } else {
        this.$vs.notify({
          title: "Please enter a valid email address and password ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
      }
    },

    loginAuth0() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLogedIn();
        return false;
      }
      this.$auth.login({ target: this.$route.query.to });
    },

    // Google login
    loginWithGoogle() {
      this.$store.dispatch("auth/loginWithGoogle", {
        notify: this.$vs.notify,
      });
    },

    // Facebook login
    loginWithFacebook() {
      this.$store.dispatch("auth/loginWithFacebook", {
        notify: this.$vs.notify,
      });
    },

    // Twitter login
    loginWithTwitter() {
      this.$store.dispatch("auth/loginWithTwitter", {
        notify: this.$vs.notify,
      });
    },

    // Github login
    loginWithGithub() {
      this.$store.dispatch("auth/loginWithGithub", {
        notify: this.$vs.notify,
      });
    },

    notifyAlreadyLogedIn() {
      this.$vs.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
    },
    registerUser() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.notifyAlreadyLogedIn();
        return false;
      }
      this.$router.push("/pages/register");
    },
  },
};
</script>

<style lang="scss">
#page-login {
  .social-login {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
